class BillItem
  constructor: (args = {}) ->
    @name = args.name
    @unitPrice = args.unitPrice || args.unit_price
    @amount = args.amount

  setName: (name) ->
    @name = name
    app.render()

  setUnitPrice: (p) ->
    @unitPrice = sanitizePrice(p)
    app.render()

  setAmount: (a) ->
    @amount = sanitizePrice(a)
    app.render()

  subTotal: ->
    (@unitPrice || 0) * (@amount || 0)

  subTotalText: ->
    formatPrice(@subTotal())

  toParams: ->
    name: @name
    unit_price: @unitPrice
    amount: @amount

module.exports = BillItem
