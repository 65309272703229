BillItem = require('./bill/bill_item')
OrderItem = require('./order_item')
OrderItemPrice = require('./order_item_price')
Arranger = require('./arranger/arranger')
R = require('ramda')

class Order
  constructor: (args = {}, trip) ->
    _.assign @, args
    @createdAt = moment(args.created_at)
    @confirmedAt = if args.confirmed_at then moment(args.confirmed_at)
    @gmoId = args.gmo_id

    if trip
      @flight_outword_date ||= trip.checkin_date
      @flight_homeword_date ||= trip.checkout_date
      @hotel_checkin_date ||= trip.checkin_date
      @hotel_checkout_date ||= trip.checkout_date
      @outword_date ||= trip.checkin_date
      @homeword_date ||= trip.checkout_date
      @showFee = trip.showFee
      @billing = trip.billing

    if typeof @flight_outword_date == 'string'
      @flight_outword_date = moment(@flight_outword_date)
    if typeof @flight_homeword_date == 'string'
      @flight_homeword_date = moment(@flight_homeword_date)
    if typeof @hotel_checkin_date == 'string'
      @hotel_checkin_date = moment(@hotel_checkin_date)
    if typeof @hotel_checkout_date == 'string'
      @hotel_checkout_date = moment(@hotel_checkout_date)
    if typeof @outword_date == 'string'
      @outword_date = moment(@outword_date)
    if typeof @homeword_date == 'string'
      @homeword_date = moment(@homeword_date)

    @flight ||= ''
    @hotel_name ||= ''
    @outword ||= ''
    @homeword ||= ''

    @orderItems =
      _.map(args.order_items, (raw) => new OrderItem(_.merge(raw, @_itemOptions())))
    @orderItems = _.sortBy @orderItems, (item) ->
      if item.startDate()
        "#{item.startDate().format('YYYYMMDDHHmm')}_#{item.traceId}"
      else
        item.traceId
    for item in @orderItems
      item.order = @
    @hotelItem =
      _.find(@orderItems, (item) -> item.id == dig(args.hotel_item, 'id')) ||
        new OrderItem(@_itemOptions())
    @outwordItem =
      _.find(@orderItems, (item) -> item.id == dig(args.outword_item, 'id')) ||
        new OrderItem(@_itemOptions())
    @homewordItem =
      _.find(@orderItems, (item) -> item.id == dig(args.homeword_item, 'id')) ||
        new OrderItem(@_itemOptions())
    @flightItem =
      _.find(@orderItems, (item) -> item.id == dig(args.flight_item, 'id')) ||
        new OrderItem(@_itemOptions())
    @flightItem.price.sectionNum = 2

    @deletedItems = []

    # 右ナビチェック用
    @checkedAt = if args.checked_at then moment(args.checked_at) else null
    @checker = if args.checker then new Arranger(args.checker) else null

    # 仕訳入力確認用
    @journalCreatedAt = if args.journal_created_at then moment(args.journal_created_at) else null
    @journalCreator = if args.journal_creator then new Arranger(args.journal_creator) else null

  dateFormat: 'YYYY-MM-DD'

  allOrderItems: ->
    _.sortBy @orderItems, (item) -> item.seqNum

  otherOrderItems: ->
    ids = _.compact(_.map([@flightItem, @hotelItem, @outwordItem, @homewordItem], (item) -> item && item.id))
    others = _.reject(@orderItems, (item) -> _.includes(ids, item.id))
    _.sortBy others, (item) -> item.seqNum

  hotelTotalPrice: ->
    prices = _.map @orderItems, (item) ->
      if item.isHotelOnly()
        if item.showFee
          item.price.price + item.price.margin.amount
        else
          item.price.price
      else
        0
    _.sum(prices)

  flightTotalPrice: ->
    prices = _.map @orderItems, (item) ->
      if item.isForeignAirOnly()
        if item.showFee
          item.price.price + item.price.margin.amount
        else
          item.price.price
      else
        0
    _.sum(prices)

  outwordTotalPrice: ->
    if _.isEmpty(dig(@outwordItem, 'elements'))
      if @showFee
        @outword_price + @outword_fee
      else
        @outword_price
    else
      @outwordItem.price.totalPrice()

  homewordTotalPrice: ->
    if _.isEmpty(dig(@homewordItem, 'elements'))
      if @showFee
        @homeword_price + @homeword_fee
      else
        @homeword_price
    else
      @homewordItem.price.totalPrice()

  domesticAirTotalPrice: ->
    prices = _.map @orderItems, (item) ->
      if item.isDomesticAirOnly()
        if item.showFee
          item.price.price + item.price.margin.amount
        else
          item.price.price
      else
        0
    _.sum(prices)

  shinTotalPrice: ->
    prices = _.map @orderItems, (item) ->
      if item.isShinkansenOnly()
        if item.showFee
          item.price.price + item.price.margin.amount
        else
          item.price.price
      else
        0
    _.sum(prices)

  otherTotalPrice: ->
    if @orderItems.length > 0
      prices = _.map @orderItems, (item) ->
        if item.isOtherOnly
          if item.showFee
            @homeword_price + @homeword_fee
          else
            @homeword_price
        else
          0
      _.sum(prices)
    else
      0

  totalPrice: ->
    if @orderItems.length > 0
      p = _.sumBy(@orderItems, (item) -> item.price.price)
      p += _.sumBy(@orderItems, (item) -> item.price.margin.amount) if @showFee
    else
      p = @flightTotalPrice() +
          @domesticAirTotalPrice() +
          @shinTotalPrice() +
          @otherTotalPrice() +
          @hotelTotalPrice()

      # old data compatibility
      p += @shinkansen_fee if @shinkansen_fee
      p += @domestic_air_fee if @domestic_air_fee
      p += @foreign_air_fee if @foreign_air_fee

    p

  totalPriceWithAll: ->
    _.sumBy(@orderItems, (item) -> item.totalPriceWithAll())

  totalMarginAmount: ->
    filteredItems = _.filter @orderItems, (item) -> item.elements.length > 0
    if filteredItems.length > 0 && @showFee
      _.sumBy(filteredItems, (item) -> item.price.margin.amount)
    else
      0

  cancelFee: ->
    filteredItems = _.filter @orderItems, (item) -> item.elements.length > 0 &&
      Object.keys(item.cancelCharges).length != 0
    if filteredItems.length > 0
      p = _.sumBy filteredItems, (item) -> item.price.price
      p += _.sumBy(filteredItems, (item) -> item.price.margin.amount) if @showFee
    else
      p = @cancel_fee

    p

  isCanceled: ->
    filteredItems = _.filter @orderItems, (item) -> item.elements.length > 0 &&
      Object.keys(item.cancelCharges).length != 0

    # orderの値が承認が必要な場合cancel_feeは0、必要ではない場合cancel_feeはnull
    (@cancel_fee != null && @cancel_fee != 0) || filteredItems.length > 0

  isTicketingExpired: ->
    expired_order_items = _.filter @orderItems, (item) ->
      item.isTicketingExpired()
    expired_order_items.length > 0

  flightDate: ->
    @flight_outword_date.format('M月D日') + '〜' + @flight_homeword_date.format('M月D日')

  stayDate: ->
    @hotel_checkin_date.format('M月D日') + '〜' + @hotel_checkout_date.format('M月D日')

  startDate: ->
    dates = _.compact(_.map(@orderItems, (item) -> item.startDate()))
    _.first(_.sortBy(dates, (d) -> d.toDate()))

  endDate: ->
    dates = _.compact(_.map(@orderItems, (item) -> item.endDate()))
    _.last(_.sortBy(dates, (d) -> d.toDate()))

  setFlight: (value) ->
    @flight = value
    app.render()

  setFlightPrice: (value) ->
    @flight_price = sanitizePrice(value)
    app.render()

  setFlightFee: (value) ->
    @flight_fee = sanitizePrice(value)
    app.render()

  setFlightOutwordDate: (value) ->
    @flight_outword_date = value
    app.render()

  setFlightHomewordDate: (value) ->
    @flight_homeword_date = value
    app.render()

  setHotelName: (value) ->
    @hotel_name = value
    app.render()

  setHotelPrice: (value) ->
    if value != "0"
      @hotel_price = sanitizePrice(value)
    else
      @hotel_price = parseInt(value)
    app.render()

  setHotelCheckinDate: (value) ->
    @hotel_checkin_date = value
    app.render()

  setHotelCheckoutDate: (value) ->
    @hotel_checkout_date = value
    app.render()

  setOutword: (value) ->
    @outword = value
    app.render()

  setOutwordPrice: (value) ->
    @outword_price = sanitizePrice(value)
    app.render()

  setOutwordFee: (value) ->
    @outword_fee = sanitizePrice(value)
    app.render()

  setOutwordDate: (value) ->
    @outword_date = value
    app.render()

  setHomeword: (value) ->
    @homeword = value
    app.render()

  setHomewordPrice: (value) ->
    @homeword_price = sanitizePrice(value)
    app.render()

  setHomewordFee: (value) ->
    @homeword_fee = sanitizePrice(value)
    app.render()

  setHomewordDate: (value) ->
    @homeword_date = value
    app.render()

  removeOldFees: ->
    @foreign_air_fee = 0
    @domestic_air_fee = 0
    @shinkansen_fee = 0

  validationErrors: ->
    targetItems = _.filter(@orderItems, (item) -> item.isEditing)
    _.map(targetItems, (item) -> item.validationErrors())

  updateParams: ->
    targetItems = _.filter(@orderItems, (item) -> item.isEditing)
    {
      order_items: _.map(targetItems, (item) -> item.updateParams())
      deleted_trace_ids: _.map(@deletedItems, (item) -> item.traceId)
    }

  billItems: ->
    items = []
    if @flight
      items.push(new BillItem(
        name: @flight
        unitPrice: @flight_price
        amount: 1
      ))
      items.push(new BillItem(
        name: '手数料'
        unitPrice: @flight_fee
        amount: 1
      ))

    if @hotel_name
      checkin = @hotel_checkin_date.format(@dateFormat)
      checkout = @hotel_checkout_date.format(@dateFormat)
      items.push(new BillItem(
        name: "#{checkin}〜#{checkout} #{@hotel_name}"
        unitPrice: @hotel_price
        amount: 1
      ))

    if @outword
      items.push(new BillItem(
        name: "#{@outword_date.format(@dateFormat)} #{@outword}"
        unitPrice: @outword_price
        amount: 1
      ))
      items.push(new BillItem(
        name: '往路手数料'
        unitPrice: @outword_fee
        amount: 1
      ))

    if @homeword
      items.push(new BillItem(
        name: "#{@homeword_date.format(@dateFormat)} #{@homeword}"
        unitPrice: @homeword_price
        amount: 1
      ))
      items.push(new BillItem(
        name: '復路手数料'
        unitPrice: @homeword_fee
        amount: 1
      ))

    items

  flightItemDescription: ->
    unless _.isEmpty(dig(@flightItem, 'elements'))
      """
      ---
      [海外]
      #{@flightItem.description()}
      """

  hotelItemDescription: ->
    unless _.isEmpty(dig(@hotelItem, 'elements'))
      """
      ---
      #{@hotelItem.description()}
      """

  outwordItemDescription: ->
    unless _.isEmpty(dig(@outwordItem, 'elements'))
      """
      ---
      [往路]
      #{@outwordItem.description()}
      """

  homewordItemDescription: ->
    unless _.isEmpty(dig(@homewordItem, 'elements'))
      """
      ---
      [復路]
      #{@homewordItem.description()}
      """

  otherItemsDescription: ->
    items = @otherOrderItems()
    if _.isEmpty(items)
      []
    else
      _.map items, (item) ->
        """
        ---
        #{item.description()}
        """

  description: ->
    d = _.compact(
      [
        @flightItemDescription()
        @hotelItemDescription()
        @outwordItemDescription()
        @homewordItemDescription()
      ]
    )
    d = d.concat(@otherItemsDescription())
    d.join('\n\n')

  traceItemDescriptions: ->
    Object.fromEntries(_.map(_.filter(@orderItems, (item) -> item.traceId), (item) -> [item.traceId, item.mappingDescription()]))

  oldFlightItemDescription: ->
    unless _.isEmpty(@flight)
      """
      [海外]
      #{@flight}
      代金：#{formatPrice(@flight_price)}
      手数料：#{formatPrice(@flight_fee)}
      """

  oldHotelItemDescription: ->
    unless _.isEmpty(@hotel_name)
      """
      [ホテル]
      #{@hotel_name}
      代金：#{formatPrice(@hotel_price)}
      """

  oldOutwordItemDescription: ->
    unless _.isEmpty(@outword)
      """
      [往路]
      #{@outword}
      代金：#{formatPrice(@outword_price)}
      手数料：#{formatPrice(@outword_fee)}
      """

  oldHomewordItemDescription: ->
    unless _.isEmpty(@homeword)
      """
      [復路]
      #{@homeword}
      代金：#{formatPrice(@homeword_price)}
      手数料：#{formatPrice(@homeword_fee)}
      """

  oldDescription: ->
    _.compact(
      [
        @oldFlightItemDescription()
        @oldHotelItemDescription()
        @oldOutwordItemDescription()
        @oldHomewordItemDescription()
      ]
    ).join('\n\n')

  hotelReservations: ->
    urls = _.map @orderItems, (item) =>
      _.map item.elements, (e) =>
        return null unless e.type == 'hotel'
        {
          reserveUrl: e.reserveUrl,
          singleReserveUrl: @_toSingleReserveUrl(e.reserveUrl),
          alternativePlanUrl: @_toAlternativePlanUrl(e.reserveUrl),
          rakutenTravelRConnectBookingSucceed: e.rakutenTravelRConnectBookingInfos.length == e.roomNum,
        }
    _.compact(_.flatten(urls))

  packageBookingUrl: ->
    item = _.first @orderItems
    if item
      item.packageBookingUrl

  handleAddItem: () ->
    @orderItems.push(new OrderItem(_.merge(@_itemOptions(), { isEditing: true, status: 3 }))) # 商品追加時のステータスのデフォルト値は要予約の 3 をセットする
    app.render()

  _toSingleReserveUrl: (original) ->
    original.replace(/f_otona_su=\d/i, 'f_otona_su=1') if original

  _toAlternativePlanUrl: (original) ->
    return unless original

    url = new URL(original)

    return unless url.searchParams.get('f_hi1')
    return unless url.searchParams.get('f_hi2')

    f_no = url.searchParams.get('f_no')
    f_otona_su = url.searchParams.get('f_otona_su')
    f_s1 = url.searchParams.get('f_s1')
    f_s2 = url.searchParams.get('f_s2')
    f_y1 = url.searchParams.get('f_y1')
    f_y2 = url.searchParams.get('f_y2')
    f_y3 = url.searchParams.get('f_y3')
    f_y4 = url.searchParams.get('f_y4')
    f_heya_su = url.searchParams.get('f_heya_su')
    f_nen1 = url.searchParams.get('f_hi1').split('-')[0]
    f_tuki1 = parseInt(url.searchParams.get('f_hi1').split('-')[1])
    f_hi1 = parseInt(url.searchParams.get('f_hi1').split('-')[2])
    f_nen2 = url.searchParams.get('f_hi2').split('-')[0]
    f_tuki2 = parseInt(url.searchParams.get('f_hi2').split('-')[1])
    f_hi2 = parseInt(url.searchParams.get('f_hi2').split('-')[2])

    """
    https://hotel.travel.rakuten.co.jp/hotelinfo/plan/\
    ?f_no=#{f_no}&f_teikei=&f_flg=PLAN&f_otona_su=#{f_otona_su}&f_s1=#{f_s1}\
    &f_s2=#{f_s2}&f_y1=#{f_y1}&f_y2=#{f_y2}&f_y3=#{f_y3}&f_y4=#{f_y4}&f_kin=\
    &f_kin2=&f_heya_su=#{f_heya_su}&f_nen1=#{f_nen1}&f_tuki1=#{f_tuki1}&f_hi1=#{f_hi1}\
    &f_nen2=#{f_nen2}&f_tuki2=#{f_tuki2}&f_hi2=#{f_hi2}&f_hak=&f_tel=&f_target_flg=\
    &f_tscm_flg=&f_p_no=&f_custom_code=&f_search_type=&f_service=&f_campaign=&f_camp_id=&f_static=1&f_tel=
    """

  handleCopyItem: (item) ->
    clone = R.clone(item)
    clone.id = null
    clone.traceId = null
    clone.isEditing = true
    clone.billedAt = null
    clone.jobType = 'reserve'
    clone.status = 9 #NEED_INSTRUCTION = 9 # 発券の指示
    originalPrices = _.map item.price.originalPrices, (op) ->
      cloneOp = R.clone(op)
      cloneOp.originalPriceTraceId = null
      cloneOp
    details = _.map item.price.priceDetails, (d) ->
      cloneDetail = R.clone(d)
      cloneDetail.detailTraceId = null
      cloneDetail

    clone.price = new OrderItemPrice {
      price: item.price.price,
      originalPrice: item.price.originalPrice,
      refund: item.price.refund,
      margin: item.price.margin,
      marginType: item.price.marginType,
      sectionNum: item.price.sectionNum,
      bulkTicketIds: item.price.bulkTicketIds,
      bulkTicketFragmentIds: item.price.bulkTicketFragmentIds,
      shareholderTickets: item.price.shareholderTickets,
      orderItemOriginalPrices: originalPrices,
      orderItemPriceDetails: details
    }

    index = @orderItems.indexOf(item)
    @orderItems.splice(index + 1, 0, clone)
    app.render()

  handleRemoveItem: (item) ->
    @deletedItems.push(item)
    index = @orderItems.indexOf(item)
    if index > -1
      @orderItems.splice(index, 1)
      if @hotelItem && @hotelItem.id == item.id
        @hotelItem = null
      if @flightItem && @flightItem.id == item.id
        @flightItem = null
      if @outwordItem && @outwordItem.id == item.id
        @outwordItem = null
      if @homewordItem && @homewordItem.id == item.id
        @homewordItem = null
      app.render()

  _itemOptions: ->
    serviceId: @serviceId
    showFee: @showFee
    marginTypes: @marginTypes
    shippingCategoryOptions: @shippingCategoryOptions
    wifiProviderOptions: @wifiProviderOptions
    carTypeOptions: @carTypeOptions
    carProviderOptions: @carProviderOptions
    billing: @billing

  isNeedReCalcMarginAmount: ->
    @orderItems.some (item) -> item.isNeedReCalcMarginAmount()

module.exports = Order
